<template>
    <ul class="list-unstyled">
        <li class="mb-2">
            <nuxt-link
                :to="{ name: 'team' }"
                :class="bigteam ? 'btn btn-outline-primary border m-1' : ''"
            >
                <Icon v="collective-logo.svg" :class="['invertible', bigteam ? 'hover-invertible' : '']" />
                <T>contact.team.name</T>
            </nuxt-link>
            <br>
            <small><T>contact.team.description</T></small>
        </li>
        <li v-if="authors === null">
            <Spinner />
        </li>
        <template v-else>
            <li v-for="author in authors" class="mb-2">
                <Icon :v="'group' in author ? 'users' : 'user'" />
                <a v-if="'link' in author" :href="author.link" target="_blank" rel="noopener">
                    <Spelling :text="convertName(author.footerName)" />
                </a>
                <span v-else>
                    <Spelling :text="convertName(author.footerName)" />
                </span>
                <nuxt-link
                    v-if="'username' in author"
                    :to="`/@${author.username}`"
                    class="badge bg-light text-dark border"
                >
                    @{{ author.username }}
                </nuxt-link>
                <br v-if="author.footerAreas">
                <small v-if="author.footerAreas">
                    <Spelling :text="author.footerAreas.replace(/,/g, ', ')" />
                </small>
            </li>
        </template>
    </ul>
</template>

<script lang="ts">
import { useFetch } from 'nuxt/app';
import { defineComponent } from 'vue';
import type { ContactAuthor } from '../locale/config.ts';
import useConfig from '../composables/useConfig.ts';
import useSpelling from '../composables/useSpelling.ts';

export default defineComponent({
    props: {
        bigteam: { type: Boolean },
    },
    setup() {
        const { convertName } = useSpelling();
        const { data: authors } = useFetch<ContactAuthor[]>('/api/admin/list/footer', { lazy: true });
        return {
            config: useConfig(),
            convertName,
            authors,
        };
    },
});
</script>
